//教研中心
<template>
  <div class="research_center">
    <div class="content" v-if="!showLoading">
      <!--导航栏-->
      <table class="Navigation">
        <tr>
          <td v-for="(item, index) in navigationData" :key="index">
            <span
              :class="currPos == index ? 'select' : 'no_select'"
              @click.stop="onSelect(item, index)"
            >
              {{ item.name }}
            </span>
          </td>
        </tr>
      </table>
      <div style="font-weight: bold; font-size: 14px; margin-top: 4px">
        优秀案例
      </div>
      <div>
        <!-- 优秀案例作品展示 -->
        <div class="work_show" v-if="wordData.length > 0">
          <div
            :class="[
              'item_course',
              (index + 1) % 4 != 0 ? 'hasMargin' : 'noMargin',
            ]"
            v-for="(item, index) in wordData"
            :key="index"
            @click.stop="doItemClick(item)"
          >
            <div>
              <!--  图片类型 -->
              <img
                v-show="currPos == 3"
                :preview="index"
                class="item_img"
                :src="item.img"
              />

              <!-- 非图片类型 -->
              <img v-show="currPos != 3" class="item_img" :src="item.img" />
            </div>

            <div class="item_name">
              <span class="name" :title="item.name">{{ item.name }}</span>
            </div>
          </div>
        </div>

        <el-empty description="暂无数据~" v-else></el-empty>
      </div>
    </div>

    <!-- Loading组件 -->
    <Loading v-if="showLoading"></Loading>

    <!-- 播放视频的弹框 -->
    <PlayVideoDialog
      v-if="showPlayVideoDialog"
      :showDialog="showPlayVideoDialog"
      @close="showPlayVideoDialog = false"
      :title="viedoName"
      :playObj="playObj"
    >
    </PlayVideoDialog>

    <!-- 播放项目的弹框 -->
    <PlayProjectDialog
      v-if="showPlayProjectDialog"
      @close="showPlayProjectDialog = false"
      :showDialog="showPlayProjectDialog"
      :title="projectName"
      :pid="pid"
    />
  </div>
</template>
<script>
//播放视频的弹框
import PlayVideoDialog from "@/views/dialog/PlayVideoDialog";

//播放项目的弹框
import PlayProjectDialog from "@/views/dialog/PlayProjectDialog";

export default {
  data() {
    return {
      //是否显示播放视频的弹框组件
      showPlayVideoDialog: false,

      //是否显示播放项目的弹框组件
      showPlayProjectDialog: false,

      //项目的pid
      pid: "",

      //项目名称
      projectName: "",

      //播放的视频名称
      viedoName: "",

      //播放的视频对象
      playObj: {
        //视频地址
        work_file: "",
        //视频封面
        work_cover: "",
      },

      //顶部导航选中的位置
      currPos: 0,

      //加载框
      showLoading: true,

      navigationData: [
        {
          name: "Scratch",
        },
        {
          name: "Python",
        },
        {
          name: "C++",
        },
        {
          name: "图片",
        },
        {
          name: "视频",
        },
      ],
      wordData: [],
    };
  },
  created() {
    //获取数据
    this.httpData();
  },
  methods: {
    //获取页面数据
    async httpData() {
      let param = new Object();
      param.type = 8;
      param.userId = this.getUserInfo().uid;
      param.token = this.getUserInfo().token;
      switch (this.currPos) {
        case 0:
          //Scratch
          param.workType = 3;
          break;
        case 1:
          //Python
          param.workType = 4;
          break;
        case 2:
          //C++
          param.workType = 5;
          break;
        case 3:
          //图片
          param.workType = 1;
          break;
        case 4:
          //视频
          param.workType = 2;
          break;
      }
      let res = await this.$http.fetchPost(this.$api.RESEARCH_PROJECT, param);
      if (res.data.state == 200) {
        //改变加载状态
        this.showLoading = false;

        //获取优秀案例
        this.wordData = res.data.data.excellentList;

        if (this.currPos == 3) {
          this.$previewRefresh();
        }
      } else {
        //改变加载状态
        this.showLoading = false;
        //直接将服务器返回的错误内容弹出提示
        this.showErrorMsg(this.getMesage(res.data.state));
      }
    },

    onSelect(item, pos) {
      if (this.currPos != pos) {
        this.currPos = pos;
        //重新刷新页面数据
        this.httpData();
      }
    },

    //条目的点击事件
    doItemClick(item) {
      //判断一下点击的类型,如果是图片类型不做处理,视频打开视频播放的弹框,Scratch打开播放项目的界面
      if (this.currPos == 0) {
        //播放项目
        this.pid = item.projectId;
        this.projectName = item.name;
        this.showPlayProjectDialog = true;
      } else if (this.currPos == 4) {
        //播放视频
        this.viedoName = item.name;

        //视频地址
        this.playObj.work_file = item.workCover;

        //视频封面
        this.playObj.work_cover = item.img;

        //打开视频的弹框
        this.showPlayVideoDialog = true;
      } else if (this.currPos == 1) {
        //Python
      } else if (this.currPos == 2) {
        //C++
      }
    },
  },
  computed: {},
  components: {
    PlayVideoDialog,
    PlayProjectDialog,
  },
};
</script>
<style lang='scss' scoped>
.research_center {
  height: calc(100vh - 87px);
  overflow-y: auto;

  .content {
    padding-top: 20px;
    padding-bottom: 20px;
    width: $common_width;
    height: calc(100vh - 87px);
    margin: 0 auto;
  }

  .Navigation {
    width: 30%;
    height: 10%;
    .no_select {
      background-color: deepskyblue;
      border-radius: 18px;
      width: 100px;
      height: 30px;
      line-height: 30px;
      display: block;
      text-align: center;
      color: white;
      cursor: pointer;
    }
    .select {
      background-color: rgb(245, 200, 51);
      border-radius: 18px;
      width: 100px;
      height: 30px;
      line-height: 30px;
      display: block;
      text-align: center;
      color: black;
      cursor: pointer;
    }
  }

  .work_show {
    position: relative;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    .item_course {
      position: relative;
      margin-bottom: 20px;
      height: 200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 23%;
      border-radius: 14px;
      color: black;
      font-weight: bold;
      cursor: pointer;
      box-shadow: darkgrey 0 0 7px 1px;
    }

    // .item_course:hover {
    //   background: rgba($color: #000000, $alpha: 0.2);
    // }
    .item_img {
      margin-top: 10px;
      width: 240px;
      height: 140px;
      object-fit: cover;
      border-radius: 4px;
    }
    .item_name {
      width: 100%;
      margin-top: 10px;
      font-size: 12px;
      font-weight: normal;
      color: $text_gray;
      .name {
        width: 50%;
        margin-left: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .mengban {
      width: 100%;
      height: 100%;
      border-radius: 14px;
      position: absolute;
      background: rgba($color: #000000, $alpha: 0.2);
    }
    .hasMargin {
      margin-right: 30px;
    }
    .noMargin {
      margin-right: 0px;
    }
  }
}

//去除滚动条
.research_center::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
</style>